<template>
  <div
    class="accordeon"
    :class="{
      'accordeon__is-open': flag,
      'accordeon__is-disabled': disabled,
      'accordeon__is-finished': finished,
    }"
  >
    <!-- <slot name="header" /> -->
    <div class="accordeon__header" @click="toggle">
      <div class="accordeon__header-stage">
        {{ data.number }}
      </div>
      <div class="accordeon__header-title-container" :class="{ attention: isAttention }">
        <div class="accordeon__header-title">
          {{ data.title }}
        </div>
        <div v-if="isAttention" class="attention-message">
          <AlertIcon class="attention-message__icon" />
          <span>{{ $t('RFP.Attention: this tab will be shared with vendors') }}</span>
        </div>
      </div>

      <svg
        class="accordeon__header-arrow"
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 4.58579L10.2929
						0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071
						0.292893C12.0976 0.683418 12.0976 1.31658 11.7071 1.70711L6.70711
						6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893
						1.70711C-0.0976311 1.31658 -0.0976311 0.683418
						0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579Z"
          fill="#92929D"
        />
      </svg>
    </div>
    <transition-expand>
      <div v-if="flag" class="accordeon__content">
        <slot name="content" />
      </div>
    </transition-expand>
  </div>
</template>

<script>
import TransitionExpand from 'devotedcg-ui-components/animations/TransitionsExpand.vue';
import AlertIcon from 'devotedcg-ui-components-v2/icons/alert-filled.svg?component';

export default {
  name: 'SimpleAccordeon',
  components: {
    TransitionExpand,
    AlertIcon,
  },
  props: {
    flag: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    finished: {
      type: Boolean,
      required: false,
    },
    isAttention: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>
.accordeon {
  box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 10px;
  &__is-disabled {
    .accordeon__header-stage {
      background: #92929d;
    }
    .accordeon__header-title {
      color: #696974;
    }
    .accordeon__header {
      cursor: default !important;
    }
  }
  &__is-finished {
    .accordeon__header-stage {
      background: #3dd598;
    }
    .accordeon__header-title {
      color: #696974;
    }
  }
  &__is-open {
    .accordeon__header {
      border-bottom: 1px solid #e2e2ea;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .accordeon__header-arrow {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &__header {
    padding: 24px;
    background: #fafafb;
    display: flex;
    border-bottom: 1px solid transparent;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    &-arrow {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%) rotate(0);
    }
    &-stage {
      background: #0062ff;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
    }
    &-title {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: #171725;
    }
    &-title-container {
      position: relative;
      &.attention {
        margin-top: -3px;
      }
    }
    .attention-message {
      position: absolute;
      left: 0;
      bottom: -10px;

      display: flex;
      margin-left: 1px;

      width: max-content;

      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #44444f;

      &__icon {
        margin-right: 4px;
      }
    }
  }
  &__content {
    padding: 32px 24px;
    background: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>
